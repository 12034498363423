<template>
  <v-select
    :value="loadedInitOptions ? value : null"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :label="localization ? selectionLabelLocalize : selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="option"
    :loading="loading"
    :clearable="clearable"
    :reduce="(item) => (reduce ? item[selectionKey] : item)"
    @search="getOption"
    @open="open"
    @input="input"
    :filter="customFilter"
  >
    <template #option="item">
      <div>
        {{ trans(item, "name", $i18n.locale) }}
      </div>
      <small class="d-block text-primary">
        {{ item.productCode }}
      </small>
    </template>
    <template #selected-option="item">
      {{ item.productCode }} - {{ trans(item, "name", $i18n.locale) }}
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template #no-options>
      {{ $t("field.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";
import { camelize } from "humps";

import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Boolean,
      default: true,
    },
    selectionKey: {
      type: String,
      default: "id",
    },
    selectionLabel: {
      type: String,
      default: "name",
    },
    localization: {
      type: Boolean,
      default: true,
    },
    query: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    selectionLabelLocalize() {
      return camelize(`${this.selectionLabel}_${this.$i18n.locale}`);
    },
  },
  data() {
    return {
      searchFields: ["nameEn", "nameKm", "productCode"],
      option: [],
      Repository: null,
      loading: false,
      loadedInitOptions: false,
    };
  },
  watch: {
    value: function (value) {
      if (!this.loadedInitOptions && value) {
        this.getOption();
      }
    },
  },
  created() {
    this.Repository = RepositoryFactory.get("product");
  },
  methods: {
    customFilter(options, search) {
      return options.filter((option) => {
        return (
          option.nameEn.toLowerCase().includes(search.toLowerCase()) ||
          option.nameKm.toLowerCase().includes(search.toLowerCase()) ||
          option.productCode.toLowerCase().includes(search.toLowerCase())
        );
      });
    },
    reinit() {
      this.option = [];
      this.loadedInitOptions = false;
      this.getOption();
    },
    reset() {
      this.$emit("input", "");
    },
    clear() {
      this.option = [];
      this.$emit("input", "");
    },
    input(value) {
      this.$emit("input", value);
      this.optionChange(value);
    },
    optionChange(value) {
      const index = this.option.findIndex((item) => {
        return item[this.selectionKey] === value;
      });

      this.$emit("optionChange", this.option[index]);
    },
    open() {
      this.getOption();
    },
    mapExcludeIds() {
      let excludeIds = [...this.option];

      return excludeIds.map((item) => {
        return item[this.selectionKey];
      });
    },
    mergeArray(arrayA, arrayB) {
      return arrayA.concat(arrayB).reduce((accumulator, currentValue) => {
        if (
          !accumulator.some((item) => {
            return item[this.selectionKey] === currentValue[this.selectionKey];
          })
        ) {
          accumulator.push(currentValue);
        }

        return accumulator;
      }, []);
    },
    getOption(search = "") {
      this.loading = true;
      const excludeIds = this.mapExcludeIds();

      this.Repository.options({
        limit: 10,
        offset: 0,
        search: search,
        includeIds: this.loadedInitOptions ? "" : [this.value].join(","),
        excludeIds: this.loadedInitOptions ? excludeIds.join(",") : "",
        locale: this.$i18n.locale,
        query: this.query,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            if (data?.list) {
              this.option = this.mergeArray(this.option, data.list);
            } else {
              this.option = this.mergeArray(this.option, data);
            }
          }
          if (!this.loadedInitOptions) {
            this.optionChange(this.value);
            this.loadedInitOptions = true;
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>