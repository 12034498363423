<template>
  <div>
    <b-row class="price-group">
      <b-col class="pr-0" cols="6">
        <b-input-group
          :prepend="prefix"
          :append="suffixLocalize ? $tc(suffix, from) : suffix"
        >
          <cleave
            class="form-control"
            v-model="from"
            :options="number"
            :raw="true"
            :disabled="disabled"
            :placeholder="$t(firstPlacehoder)"
          />
        </b-input-group>
      </b-col>
      <b-col class="pl-0" cols="6">
        <b-input-group
          :prepend="prefix"
          :append="suffixLocalize ? $tc(suffix, to) : suffix"
        >
          <cleave
            class="form-control"
            v-model="to"
            :options="number"
            :raw="true"
            :disabled="disabled"
            :placeholder="$t(secondPlacehoder)"
          />
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BInputGroup } from "bootstrap-vue";
import Cleave from "vue-cleave-component";

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    Cleave,
  },
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {
      default: "",
    },
    firstPlacehoder: {
      default: "",
    },
    secondPlacehoder: {
      default: "",
    },
    suffix: {
      default: "",
    },
    suffixLocalize: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        if (typeof this.value == "object" && this.value.length == 2) {
          this.from = this.value[0];
          this.to = this.value[1];
        }
      } else {
        this.from = null;
        this.to = null;
      }
    },
    from(value) {
      this.emitInput(value, this.to);
    },
    to(value) {
      this.emitInput(this.from, value);
    },
  },
  data() {
    return {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      from: null,
      to: null,
    };
  },
  methods: {
    emitInput(from, to) {
      this.$emit("input", [from, to]);
    },
  },
};
</script>