<template>
  <div>
    <date-range-picker
      :key="`calendar-${$i18n.locale}`"
      class="w-100"
      ref="picker"
      v-model="dateRange"
      :auto-apply="true"
      :show-dropdowns="true"
      :locale-data="{
        format: 'YYYY-MM-DD',
        separator: ' - ',
        daysOfWeek: [
          $t('day.sun'),
          $t('day.mon'),
          $t('day.tue'),
          $t('day.wed'),
          $t('day.thu'),
          $t('day.fri'),
          $t('day.sat'),
        ],
        monthNames: [
          $t('month.jan'),
          $t('month.feb'),
          $t('month.mar'),
          $t('month.apr'),
          $t('month.may'),
          $t('month.jun'),
          $t('month.jul'),
          $t('month.aug'),
          $t('month.sep'),
          $t('month.oct'),
          $t('month.nov'),
          $t('month.dec'),
        ],
      }"
      :ranges="customRanges"
      @update="onChange"
    >
      <template v-slot:input="picker">
        {{ picker.startDate | formatDate("LL") }}
        <span v-if="picker.startDate || picker.endDate">-</span>
        {{ picker.endDate | formatDate("LL") }}
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
  components: {
    DateRangePicker,
  },
  props: {
    value: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    disableType: {
      type: String,
      default: "",
    },
  },
  watch: {
    value() {
      if (this.value != null) {
        if (typeof this.value == "object" && this.value.length == 2) {
          this.dateRange.startDate = this.value[0];
          this.dateRange.endDate = this.value[1];
        }
      } else {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      }
    },
  },
  computed: {
    customRanges() {
      let todayLabel = this.$t("field.today");
      let ranges = {};
      ranges[this.$t("field.today")] = [new Date(moment()), new Date(moment())];
      ranges[this.$t("field.yesterday")] = [
        new Date(moment().subtract(1, "day")),
        new Date(moment().subtract(1, "day")),
      ];
      ranges[this.$t("field.thisWeek")] = [
        new Date(moment().startOf("week")),
        new Date(moment().endOf("week")),
      ];
      ranges[this.$t("field.lastWeek")] = [
        new Date(moment().subtract(1, "weeks").startOf("week")),
        new Date(moment().subtract(1, "weeks").endOf("week")),
      ];
      ranges[this.$t("field.thisMonth")] = [
        new Date(moment().startOf("month")),
        new Date(moment().endOf("month")),
      ];
      ranges[this.$t("field.lastMonth")] = [
        new Date(moment().subtract(1, "months").startOf("month")),
        new Date(moment().subtract(1, "months").endOf("month")),
      ];
      ranges[this.$t("field.thisYear")] = [
        new Date(moment().startOf("year")),
        new Date(moment().endOf("year")),
      ];
      return ranges;
    },
  },
  data() {
    return {
      moment,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      from: null,
      to: null,
    };
  },
  methods: {
    onChange() {
      this.emitInput();
    },
    emitInput() {
      this.$emit("input", [
        moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        moment(this.dateRange.endDate).format("YYYY-MM-DD"),
      ]);
    },
  },
};
</script>